import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Icon, Typography, Col, Row } from 'antd';

import logoImg from '../../../images/wingops-logo-blue.svg';

import styles from './login.less';
import { translate } from '../../utils/translator';

const Login = (props) => {
	const { loading, form } = props;

	const { getFieldDecorator } = form;

	const handleSubmit = (e) => {
		e.preventDefault();

		form.validateFields((err, values) => {
			if (!err) {
				props.onSubmit(values);
			}
		});
	};

	return (
		<Fragment>
			<div className={styles.form}>
				<div className={styles.formContent}>
					<div className={styles.logo}>
						<img alt="logo" src={logoImg} />
					</div>
					<Form style={{ marginBottom: 16 }} onSubmit={handleSubmit} className="login-form">
						<Form.Item>
							{getFieldDecorator('email', {
								rules: [{ required: true, message: 'Please input your email!' }]
							})(<Input
								type="email"
								prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
								placeholder="Email"
							/>)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator('password', {
								rules: [
									{ required: true, message: 'Please input your Password!' }
								]
							})(<Input
								prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
								type="password"
								placeholder="Password"
							/>)}
						</Form.Item>
						<Form.Item>
							<Button loading={loading} type="primary" htmlType="submit">
								Login
							</Button>
						</Form.Item>
						<a href="/forgot-password">
							Forgot password?
						</a>
					</Form>
				</div>
				<Row className={styles.termsIntro}>
					<Typography.Text style={{ fontSize: 10 }} type="secondary">
						{translate('By using Wingops, you agree to our terms and conditions.')}
					</Typography.Text>
				</Row>
				<Row className={styles.terms} gutter={16}>
					<Typography.Text
						onClick={() => window.open('https://wingops-public.s3.eu-central-1.amazonaws.com/WINGOPS_PRIVACY_POLICY_01.05.2023.pdf')}
						style={{ fontSize: 10, marginRight: 4, cursor: 'pointer' }}
						type="secondary"
					>
						{translate('Privacy Policy')}
					</Typography.Text>
					<Typography.Text
						onClick={() => window.open('https://wingops-public.s3.eu-central-1.amazonaws.com/WINGOPS_TERMS_AND_CONDITIONS_01.05.2023.pdf')}
						style={{ fontSize: 10, marginLeft: 4, cursor: 'pointer' }}
						type="secondary">
						{translate('Terms and Conditions')}
					</Typography.Text>
				</Row>
			</div>
		</Fragment>
	);
};

Login.propTypes = {
	form: PropTypes.object,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool
};

export default Form.create({ name: 'login_form' })(Login);

import React, { useState } from 'react';
import { message } from 'antd';
import LoginComponent from '../../components/login/login';
import { apiLogin } from '../../api/index';
import { translate } from '../../utils/translator';

export default function LoginContainer() {
	const [loading, setLoading] = useState(false);

	async function onLoginSubmit(payload) {
		setLoading(true);
		const request = await apiLogin({ payload }).catch((err) => {
			if (err.status === 429) {
				message.error(translate('Too many requests, please wait.'));
			} else {
				message.error('Wrong email or password!');
			}
			setLoading(false);
		});

		if (request) {
			setLoading(false);
			const urlSearchParams = new URLSearchParams(window.location.search);
			const params = Object.fromEntries(urlSearchParams.entries());

			if (params.redirect) {
				window.location.href = params.redirect;
				return;
			}

			window.location.href = '/';
		}
	}

	return (<LoginComponent
		loading={loading}
		onSubmit={onLoginSubmit}
	/>);
}

import { translate } from './translator';

export const SQL_DATE_FORMAT = 'YYYY-MM-DD';
export const SQL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DISPLAY_DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DISPLAY_TIME_FORMAT = 'HH:mm';

export const AIRCRAFT_DATE_FIELDS = ['last_maintenance_date', 'next_maintenance_date'];
export const SHOPPING_LIST_DATE_FIELDS = ['estimated_arrival_date'];
export const VENDOR_DATE_FIELDS = ['evaluation_expiration_date'];
export const STAFF_DATE_FIELDS = ['date_of_birth'];
export const LOGBOOK_DATE_FIELDS = ['date', 'crs_date'];
export const SB_DATE_FIELDS = ['due_date'];
export const OPERATOR_DATE_FIELDS = ['contract_expiration_date'];
export const AIRCRAFT_STATUSES = ['in_flight', 'in_maintenance', 'needs_maintenance', 'out_of_order', 'inactive'];
export const OPERATION_STATUSES = ['not_due', 'near_due', 'past_due'];
export const WORK_ORDER_STATUSES = ['in_preparation', 'ready_for_work', 'in_progress', 'done'];
export const STAFF_STATUSES = ['current', 'in_maintenance', 'approaching', 'overdue'];
export const CERTIFICATE_DATE_FIELDS = ['last_done', 'next_due'];
export const TRAINING_DATE_FIELDS = ['last_done', 'next_due'];
export const CERTIFICATE_STATUSES = ['not_due', 'near_due', 'past_due'];
export const TRAINING_STATUSES = ['not_due', 'near_due', 'past_due'];
export const MODS_REPAIRS_DATE_FIELDS = ['crs_date'];
export const REVISION_DATE_FIELDS = ['approved_date'];
export const AIRCRAFT_DEFECTS_DATE_FIELDS = ['date', 'due_date', 'done_date'];
export const AWR_FINDING_DATE_FIELDS = ['rectification_date', 'rectification_due_date'];
export const AWR_DATE_FIELDS = ['due_date'];


export const COMPONENT_DATE_FIELDS = ['installed_date', 'due_date', 'incoming_inspection_date', 'shelflife_expires', 'inspection_date', 'last_done_date'];
export const USED_MATERIAL_DATE_FIELDS = ['date'];
export const TOOL_DATE_FIELDS = ['last_inspection_date', 'next_inspection_date', 'last_calibration_date', 'next_calibration_date'];
export const COMPONENT_HISTORY_DATE_FIELDS = ['date'];
export const DEFECT_DATE_FIELDS = ['done_date'];

export const DENT_BUCKLE_DATE_FIELDS = ['date'];
export const ISSUE_DATE_FIELDS = ['due_date'];

export const WORK_ORDER_OP_DATE_FIELDS = ['done_date'];

export const PART_DATE_FIELDS = ['expire_date'];

export const OPERATION_DATE_FIELDS = ['last_done_date', 'next_due_date', 'threshold_date'];

export const UTILIZATION_DATE_FIELDS = ['date_lnd', 'date_toff'];

export const COMPONENT_DRAWER_HOURS_RELATED_FIELDS = ['installed', 'last_done', 'installed_tsn', 'installed_tso', 'tsn', 'tso'];
export const OPERATION_DRAWER_HOURS_RELATED_FIELDS = ['next_due', 'last_done'];
export const UTILIZATION_DRAWER_HOURS_RELATED_FIELDS = ['last', 'current', 'total'];

export const TRACKING_PARAMETER_TYPE_HOURS = 'hours';
export const TRACKING_PARAMETER_TYPE_METER = 'meter';

export const SIGNATURE_TYPE_WORK_ORDER = 'work_order';
export const SIGNATURE_TYPE_TASK = 'task';

export const APPROVAL_TYPE_PRE = 'pre';
export const APPROVAL_TYPE_POST = 'post';
export const APPROVAL_TYPE_MATERIALS_USED = 'materials_used';
export const APPROVAL_TYPE_TOOLS_USED = 'tools_used';

export const COMPONENT_TYPE_COMPONENT = 'component';
export const COMPONENT_TYPE_MATERIAL = 'material';

export const COMPONENT_ACTION_INSTALL = 'install';
export const COMPONENT_ACTION_REMOVE = 'remove';
export const COMPONENT_ACTION_INSPECT = 'inspect';

export const COMPONENT_DEFAULT_CATEGORIES = ['Component', 'Part', 'Hard time', 'LLP', 'OC/CM'];

export const WORK_ORDER_TYPE_AIRCRAFT = 'aircraft';
export const WORK_ORDER_TYPE_ITEM = 'item';

export const ITEM_TYPE_ISSUE = 'issue';
export const ITEM_TYPE_WORK_ORDER = 'work_order';

export const VIEW_TYPE_AIRCRAFT_TASKS = 'tasks';
export const VIEW_TYPE_TEMPLATE_TASKS = 'template-tasks';
export const VIEW_TYPE_WAREHOUSE = 'warehouse';
export const VIEW_TYPE_COMPONENTS = 'components';
export const VIEW_TYPE_TOOLS = 'tools';
export const VIEW_TYPE_WORK_ORDERS = 'work-orders';
export const VIEW_TYPE_SERVICE_BULLETINS = 'service-bulletins';
export const VIEW_TYPE_SHOPPING_LIST = 'shopping-list';
export const VIEW_TYPE_FLIGHT_LOG = 'flight-log';
export const VIEW_TYPE_STAFF_LOGBOOK = 'staff-logbook';
export const VIEW_TYPE_AIRCRAFT_DEFECTS = 'aircraft-defects';
export const VIEW_TYPE_MODS_REPAIRS = 'mods-repairs';
export const VIEW_TYPE_DENTS_BUCKLES = 'dents-buckles';
export const VIEW_TYPE_READ_AND_SIGN = 'read-and-sign';
export const VIEW_TYPE_WAREHOUSE_HISTORY = 'warehouse-history';
export const VIEW_TYPE_STAFF = 'staff';
export const VIEW_TYPE_AIRCRAFT = 'aircraft';
export const VIEW_TYPE_MATERIALS_USED = 'materials-used';


export const WORK_ORDER_TYPE_DATA = {
	[WORK_ORDER_TYPE_AIRCRAFT]: {
		text: translate('Aircraft')
	},
	[WORK_ORDER_TYPE_ITEM]: {
		text: translate('Item')
	}
};

export const DEFAULT_PAGE_SIZE = 50;

export const ISSUE_PRIORITY_MAP = {
	4: { text: translate('AOG'), color: 'red' },
	3: { text: translate('HIGH'), color: 'orange' },
	2: { text: translate('MEDIUM'), color: 'blue' },
	1: { text: translate('LOW'), color: 'green' },
	0: { text: translate('NO PRIORITY'), color: null }
};

export const DEFAULT_SB_CATEGORIES = [
	'Airframe',
	'Engine',
	'Propeller',
	'Avionics'
];

export const DEFAULT_SB_TYPES = [
	'Mandatory',
	'Recommended',
	'Informational',
	'Optional'
];

export const DEFAULT_DENTS_BUCKLES_TYPES = [
	'Dent',
	'Buckle'
];

export const DEFAULT_LOGBOOK_ACTIONS = [
	'Performed',
	'Inspected',
	'Completed'
];

export const CERTIFICATE_TYPE_CERTIFICATE = 'certificate';
export const CERTIFICATE_TYPE_CA = 'ca';
export const CERTIFICATE_TYPE_AML = 'aml';

export const CERTIFICATE_TYPE_TITLE = {
	[CERTIFICATE_TYPE_CERTIFICATE]: translate('Certificate'),
	[CERTIFICATE_TYPE_CA]: translate('Certification Authorization'),
	[CERTIFICATE_TYPE_AML]: translate('Aircraft Maintenance Licence')
};

export const COMPONENT_TYPE_TITLE = {
	[COMPONENT_TYPE_MATERIAL]: translate('Material'),
	[COMPONENT_TYPE_COMPONENT]: translate('Component')
};

export const FORM_VALIDATION_DEFAULT_MESSAGES = {
	default: 'Validation error on field value',
	required: 'Value is required',
	enum: 'Value must be one of %s',
	whitespace: 'Value cannot be empty',
	date: {
		format: 'Value date %s is invalid for format %s',
		parse: 'Value date could not be parsed, %s is invalid ',
		invalid: 'Value date %s is invalid'
	},
	types: {
		string: 'Value is not a string',
		method: 'Value is not a %s (function)',
		array: 'Value is not an %s',
		object: 'Value is not an %s',
		number: 'Value is not a number',
		date: 'Value is not a date',
		boolean: 'Value is not a boolean',
		integer: 'Value is not a number',
		float: 'Value is not a number',
		regexp: 'Value is not a valid %s',
		email: 'Value is not a valid email',
		url: 'Value is not a valid URL',
		hex: 'Value is not a valid %s'
	},
	string: {
		len: 'Value must be exactly %s characters',
		min: 'Value must be at least %s characters',
		max: 'Value cannot be longer than %s characters',
		range: 'Value must be between %s and %s characters'
	},
	number: {
		len: 'Value must equal %s',
		min: 'Value cannot be less than %s',
		max: 'Value cannot be greater than %s',
		range: 'Value must be between %s and %s'
	},
	array: {
		len: 'Value must be exactly %s in length',
		min: 'Value cannot be less than %s in length',
		max: 'Value cannot be greater than %s in length',
		range: 'Value must be between %s and %s in length'
	},
	pattern: {
		mismatch: 'Value value %s does not match pattern %s'
	}
};

export const SKIP_CHANGELOG_KEYS = ['owner_id', 'permission_group_id', 'user_group_ids', 'required_signature_ids', 'checklist_ids', 'pic_id', 'sic_id'];
export const PUSHER_EVENT_GENERATE_PDF = 'generate_pdf';
export const PUSHER_EVENT_UPDATE_AIRCRAFT_STATUSES = 'update_aircraft_statuses';

export const UPDATE_AIRCRAFT_STATUSES_JOB_STATUS_PROCESSING = 'processing';
export const UPDATE_AIRCRAFT_STATUSES_JOB_STATUS_ERROR = 'error';
export const UPDATE_AIRCRAFT_STATUSES_JOB_STATUS_COMPLETED = 'completed';

export const CHANGELOG_DYNAMIC_KEY_TITLES = {
	threshold: translate('Threshold'),
	threshold_period: translate('After threshold'),
	period: translate('Interval'),
	variation: translate('Tolerance'),
	adjustment_reason: translate('adjustment reason'),
	adjustment: translate('Adjustment'),
	last_done: translate('Last done'),
	next_due: translate('Next due'),
	max_next_due: translate('Max. limit'),
	max_due: translate('Max. limit'),
	near_due: translate('Near due'),
	tsn: translate('TSN'),
	tso: translate('TSO'),
	installed_tsn: translate('Installed TSN'),
	installed_tso: translate('Installed TSO'),
	installed: translate('Installed'),
	current: translate('Duration'),
	last: translate('Last'),
	total: translate('Total'),
	due: translate('Next due')
};

export const CHANGELOG_FIELDS_TITLES = {
	tool: {
		owner_name: translate('Inventory owner'),
		marking: translate('ID / Marking'),
		name: translate('Name'),
		status: translate('Status'),
		serial: translate('S/N'),
		part_nr: translate('P/N'),
		range: translate('Range'),
		calibration_required: translate('Calibration required'),
		last_calibration_date: translate('Last calibration date'),
		next_calibration_date: translate('Next calibration date'),
		calibration_interval_days: translate('Calibration interval days'),
		calibration_near_due_days: translate('Calibaration near due days'),
		certificate: translate('Certificate'),
		standard: translate('Standard'),
		last_inspection_date: translate('Last inspection date'),
		next_inspection_date: translate('Next inspection date'),
		inspection_interval_days: translate('Inspection interval days'),
		inspection_near_due_days: translate('Inspection near due days'),
		additional_info: translate('Info'),
		location: translate('Location'),
		shelf_location: translate('Shelf location'),
		model: translate('Model'),
		manufacturer: translate('Manufacturer')
	},
	aircraft: {
		tags: translate('Tags'),
		checklists: translate('Checklists'),
		registration: translate('Registration'),
		inactive: translate('Inactive'),
		model: translate('Model'),
		statuses_updated_at: translate('Last status update'),
		type_code: translate('Type code'),
		serial: translate('S/N'),
		status: translate('Status'),
		maintenance_data_ref: translate('Maintenance data ref'),
		appendix: translate('Appendix'),
		mp_date: translate('Maintenance Program date'),
		engines_data: translate('Engines info'),
		propellers_data: translate('Propellers info'),
		additional_info: translate('Additional info'),
		enabled_parameters: translate('Enabled parameters')
	},
	aircraft_defect: {
		date: translate('Date'),
		type: translate('Type'),
		status: translate('Status'),
		description: translate('Description'),
		log_reference: translate('TLog'),
		work_order_reference: translate('Work order'),
		reference: translate('Reference'),
		due_date: translate('Next due Date'),
		near_due_days: translate('Near due Days'),
		done_date: translate('Done date'),
		additional_info: translate('Additional info')
	},
	operation: {
		name: translate('Name'),
		op_nr: translate('Name'), // Legacy
		description: translate('Description'),
		required_signatures: translate('Required signatures'),
		awl: translate('AWL'),
		ata: translate('ATA'),
		task_group: translate('Task group'),
		has_tasks: translate('Subtasks'),
		category: translate('Category'),
		status: translate('Status'),
		recurring: translate('Recurring'),
		price: translate('Price'),
		threshold_enabled: translate('Threshold enabled'),
		threshold_date: translate('Threshold date'),
		threshold_period_days: translate('After threshold days'),
		period_days: translate('Interval days'),
		days_variation: translate('Tolerance days'),
		near_due_days: translate('Near due Days'),
		adjustment_days: translate('Adjustment Days'),
		last_done_date: translate('Last done date'),
		next_due_date: translate('Next due Date'),
		max_next_due_date: translate('Max. limit Date'),
		enabled_parameters: translate('Enabled parameters')
	},
	operation_task: {
		is_optional: translate('Optional'),
		description: translate('Description'),
		required_signatures: translate('Required signatures'),
		category: translate('Category')
	},
	component: {
		owner_name: translate('Inventory owner'),
		batch: translate('Batch'),
		form_tracking_nr: translate('Form tracking number'),
		awl: translate('AWL'),
		type: translate('Type'),
		parent_component_id: translate('Parent component'),
		operation_id: translate('Linked operation'),
		name: translate('Nr.'),
		barcode: translate('Barcode'),
		manufacturer: translate('Manufacturer'),
		model: translate('Model'),
		status: translate('Status'),
		zone: translate('Zone'),
		position: translate('Position'),
		serial: translate('S/N'),
		part_nr: translate('Part nr'),
		alternative_part_nr: translate('Alternative part nr'),
		overhauled: translate('Overhauled'),
		aircraft_id: translate('Aircraft'),
		additional_info: translate('Additional info'),
		quantity: translate('Quantity'),
		minimum_quantity: translate('Minimum quantity'),
		location: translate('Location'),
		shelf_location: translate('Shelf location'),
		incoming_inspection_date: translate('Incoming inspection date'),
		shelflife_expires: translate('Shelflife expires'),
		condition: translate('Condition'),
		price: translate('Price'),
		unit: translate('Unit'),
		inspection_performed_by: translate('Inspection performed by'),
		compliance_reviewed_by: translate('Compliance reviewed by'),
		near_due_days: translate('Tolerance days'),
		tsn_days: translate('TSN days'),
		tso_days: translate('TSO days'),
		installed_date: translate('Installed date'),
		installed_tsn_days: translate('Installed TSN days'),
		installed_tso_days: translate('Installed TSO days'),
		next_due_date: translate('Next due Date'),
		max_next_due_date: translate('Max. limit Date'),
		station: translate('Station'),
		enabled_parameters: translate('Enabled parameters')
	},
	service_bulletin: {
		type: translate('Type'),
		description: translate('Description'),
		directive_easa: translate('EASA'),
		directive_faa: translate('FAA'),
		directive_tcca: translate('TCCA'),
		service_bulletin: translate('SB'),
		other_caa: translate('Other'),
		category: translate('Category'),
		manufacturer: translate('Manufacturer'),
		model: translate('Model'),
		due_date: translate('Next due Date'),
		near_due_days: translate('Near due Days'),
		operation: translate('Included in tasks'),
		compliance_status: translate('Compliance status'),
		status: translate('Due status'),
		compliance_information: translate('Compliance information'),
		work_order: translate('Compliance information'), // Legacy
		applicable: translate('Applicable'),
		recurrent: translate('Repetitive'),
		open: translate('Open'),
		max_due_date: translate('Max. limit Date'),
		additional_info: translate('Additional info')
	},
	mods_repairs: {
		type: translate('Type'),
		crs_date: translate('Date'),
		description: translate('Description'),
		work_order: translate('Work order/document ref.'),
		reference: translate('Reference'),
		additional_info: translate('Additional info'),
	},
	dent_buckle: {
		status: translate('Status'),
		type: translate('Type'),
		date: translate('Date'),
		no: translate('No'),
		description: translate('Description and location'),
		dimensions: translate('Dimensions'),
		reference: translate('Reference')
	},
	defect: {
		restification_method: translate('Rectification method'),
		reference: translate('Reference'),
		done_date: translate('Done date'),
		required_signatures: translate('Required signatures'),
		price: translate('Price'),
		deferred: translate('Deferred'),
		comment: translate('Comment')
	},
	work_order_op: {
		done_date: translate('Done date'),
		price: translate('Price'),
		required_signatures: translate('Required signatures'),
		comment: translate('Comment'),
		deferred: translate('Deferred'),
		assigned_to: translate('Assigned to')
	},
	work_order: {
		nr: translate('#No'),
		description: translate('Description'),
		done_date: translate('CRS Date/Time'),
		start_date: translate('Work start date'),
		total_price: translate('Total price'),
		end_date: translate('Work end date'),
		planned_start_date: translate('Planned start date'),
		planned_end_date: translate('Planned end date'),
		status: translate('Status'),
		customer_name: translate('Customer'),
		release_to_service_text: translate('Certificate of release to service'),
		maintenance_performed_at: translate('Maintenance performed at'),
		maintenance_data_ref: translate('Maintenance data ref'),
		certificate_nr: translate('Certificate no'),
		reopened: translate('Reopened'),
		info: translate('Info'),
		closed: translate('Closed'),
		updated_statuses: translate('Statuses updated'),
		tags: translate('Tags'),
		reasons_for_issue: translate('Reasons for issue'),
		commenced_on: translate('Commenced on'),
		crs_reference_number: translate('CRS Reference number'),

		aircraft_model: translate('Aircraft model'),
		aircraft_serial: translate('Aircraft serial'),
		aircraft_registration: translate('Aircraft registration'),
		customer_data: translate('Customer'),

		// Item data
		item_part_nr: translate('Item part number'),
		item_serial: translate('Item serial number'),
		item_description: translate('Item description'),
		remarks: translate('Remarks'),
		work_type: translate('Work type'),
		form_tracking_number: translate('Form tracking number'),
		total_work_time: translate('Total work time'),
		limitations: translate('Limitations')
	},
	materials_used: {
		amount_used: translate('Amount'),
		date: translate('Date')
	},
	note: {
		content: translate('Text')
	},
	utilization: {
		date_lnd: translate('Landing date'),
		date_toff: translate('Takeoff date'),
		time_lnd: translate('Landing time'),
		time_toff: translate('Takeoff time'),
		date_on_block: translate('On-block date'),
		date_off_block: translate('Off-block date'),
		time_on_block: translate('On-block time'),
		time_off_block: translate('Off-block time'),
		tlog: translate('TLOG'),
		pic_name: translate('PIC'),
		sic_name: translate('SIC'),
		flight: translate('Flight'),
		from: translate('From'),
		to: translate('To'),
		remarks: translate('Remarks'),
		flight_type: translate('Flight type')
	},
	issue: {
		description: translate('Description'),
		comment: translate('Comment'),
		is_order: translate('Order'),
		ordered: translate('Marked as ordered'),
		done: translate('Done'),
		is_overdue: translate('Overdue'),
		due_date: translate('Next due Date')
	},
	template: {
		name: translate('Name'),
		description: translate('Description'),
		work_type: translate('Work type'),
		model: translate('Model'),
		serial: translate('Serial'),
		registration: translate('Registration'),
		part_nr: translate('P/N'),
		remarks: translate('Remarks'),
		easa_form_13_1_checked: translate('13a. approved design data and are in condition for safe operation'),
		easa_form_13_2_checked: translate('13a. non-approved design data specified in block 12'),
		easa_form_14_1_checked: translate('14a. Part-145.A.50 Release to Service'),
		easa_form_14_2_checked: translate('14a. Other regulation specified in block 12'),
		faa_form_13_1_checked: translate('13a. Approved design data and are in condition for safe operation.d'),
		faa_form_13_2_checked: translate('13a. Non-approved design data specified in Block 12.'),
		faa_form_14_1_checked: translate('14a. 14CFR 43.9 Return to Service'),
		faa_form_14_2_checked: translate('14a. Other regulation specified in Block 12'),
		type: translate('Type'),
		additional_info: translate('Additional info'),
		enabled_parameters: translate('Enabled parameters')
	},
	certificate: {
		name: translate('Name'),
		serial: translate('Serial'),
		type: translate('Type'),
		scope: translate('Scope and limitation'),
		special_authorization: translate('Special authorization'),
		active_aml: translate('Active AML'),
		active_ca: translate('Active CA'),
		user_groups: translate('User groups'),
		licence_category: translate('Licence category'),
		subcategory: translate('Subcategory'),
		description: translate('Additional info'),
		interval: translate('Valid for'),
		variation: translate('Near due'),
		last_done: translate('Issue date'),
		next_due: translate('Expiration date'),
		status: translate('Status')
	},
	training: {
		name: translate('Name'),
		type: translate('Type'),
		user_groups: translate('User groups'),
		recurring: translate('Recurring'),
		description: translate('Additional info'),
		interval: translate('Interval'),
		variation: translate('Near due'),
		last_done: translate('Performed date'),
		next_due: translate('Expiration date'),
		status: translate('Status')
	},
	certificate_template: {
		name: translate('Name'),
		serial: translate('Serial'),
		type: translate('Type'),
		scope: translate('Scope and limitation'),
		special_authorization: translate('Special authorization'),
		user_groups: translate('User groups'),
		licence_category: translate('Licence category'),
		subcategory: translate('Subcategory'),
		description: translate('Additional info'),
		interval: translate('Valid for'),
		variation: translate('Near due')
	},
	training_template: {
		name: translate('Name'),
		type: translate('Type'),
		user_groups: translate('User groups'),
		recurring: translate('Recurring'),
		description: translate('Additional info'),
		interval: translate('Interval'),
		variation: translate('Near due')
	},
	user: {
		name: translate('Name'),
		is_superadmin: translate('Admin'),
		inactive: translate('Inactive'),
		permission_group: translate('Permission group'),
		user_groups: translate('User groups'),
		role_title: translate('Role title'),
		status: translate('Status'),
		number: translate('Number'),
		positions: translate('Position'),
		type_of_employments: translate('Type of employment'),
		stations: translate('Stations'),
		aml_no: translate('Aircraft Maintenance Licence no.'),
		aml_category: translate('Aircraft Maintenance Licence category'),
		ca_no: translate('Certification Authorization no.'),
		amo_no: translate('Maintenance Organisation Approval Certificate'),
		moacs: translate('Maintenance Organisation Approval Certificate'),
		additional_info: translate('Additional info')
	},
	operator: {
		name: translate('Name'),
		inactive: translate('Inactive'),
		contact_info: translate('Contact info'),
		contract_type: translate('Contract type'),
		fleet_type: translate('Fleet type'),
		stations: translate('Stations type'),
		crs_no: translate('CRS No.'),
		near_due_days: translate('Near due Days'),
		contract_expiration_date: translate('Contract expiration date'),
		status: translate('Status')
	},
	vendor: {
		name: translate('Name'),
		inactive: translate('Inactive'),
		contact_info: translate('Contact info'),
		scope: translate('Scope'),
		certificate: translate('Certificate'),
		near_due_days: translate('Near due Days'),
		evaluation_expiration_date: translate('Evaluation expiration date'),
		status: translate('Status')
	},
	customer: {
		name: translate('Name'),
		inactive: translate('Inactive'),
		email: translate('Email'),
		phone: translate('Phone'),
		address: translate('Address'),
		reference: translate('Reference'),
		description: translate('Description')
	},
	user_logbook_entries: {
		action: translate('Action'),
		task_description: translate('Description'),
		task: translate('Operation/task'),
		ata: translate('ATA'),
		work_order: translate('Work order'),
		date: translate('Date'),
		crs_date: translate('CRS date'),
		aircraft_registration: translate('A/C registration'),
		aircraft_type: translate('A/C type')
	},
	awr: {
		description: translate('Description'),
		statement: translate('Statement'),
		due_date: translate('Due Date'),
		near_due_days: translate('Near due Days'),
	},
	awr_finding: {
		corrective_action_performed: translate('Corrective action performed'),
		description: translate('Description'),
		corrective_action: translate('Corrective action required'),
		level: translate('Level'),
		rectification_date: translate('Rectification date'),
		rectification_due_date: translate('Due Date'),
		rectification_near_due_days: translate('Rectification near due days'),

	},
	awr_row: {
		type: translate('Type'),
		description: translate('Description'),
		information: translate('Information'),
		compliance_note: translate('Compliance note'),
		input_value: translate('Value'),
		checkbox_value: translate('OK'),
	}
};

export const USER_STATUSES = ['not_due', 'near_due', 'past_due', 'inactive'];
export const USER_ROLES = ['admin', 'inspector', 'mechanic'];

export const SIGNATURE_TYPE_WORK_CERTIFIED_BY = 'certified_by';
export const SIGNATURE_TYPE_WORK_PERFORMED_BY = 'performed_by';

export const SIGNATURE_ACTION_COMPLETE = 'complete';
export const SIGNATURE_ACTION_CERTIFY = 'certify';
export const SIGNATURE_ACTION_APPROVE = 'approve';

export const SIGNATURE_TYPE_TITLE = {
	[SIGNATURE_TYPE_WORK_CERTIFIED_BY]: translate('Inspected'),
	[SIGNATURE_TYPE_WORK_PERFORMED_BY]: translate('Performed')
};

export const WORK_TYPE_WORK_ORDER = 'work_order';

export const WORK_TYPE_TITLE = {
	[WORK_TYPE_WORK_ORDER]: translate('Work order')
};

export const OBJECT_TYPE_OPERATION = 'operation';
export const OBJECT_TYPE_WORK_ORDER = 'work_order';
export const OBJECT_TYPE_OPERATION_TASK = 'operation_task';
export const OBJECT_TYPE_DEFECT = 'defect';
export const OBJECT_TYPE_COMPONENT = 'component';

export const DEFAULT_COLORS = [
	'#eb2f96',
	'#f50',
	'#fa541c',
	'#fa8c16',
	'#faad14',
	'#a0d911',
	'#52c41a',
	'#13c2c2',
	'#2db7f5',
	'#108ee9',
	'#722ed1'
];

export const LOGISTIC_STATUS_DRAFT = 'draft';
export const LOGISTIC_STATUS_PENDING_APPROVAL = 'pending_approval';
export const LOGISTIC_STATUS_APPROVED = 'approved';
export const LOGISTIC_STATUS_REJECTED = 'rejected';
export const LOGISTIC_STATUS_IN_PROGRESS = 'in_progress';
export const LOGISTIC_STATUS_ON_HOLD = 'on_hold';
export const LOGISTIC_STATUS_PAYMENT_PENDING = 'payment_pending';
export const LOGISTIC_STATUS_ARCHIVED = 'archived';
export const LOGISTIC_STATUS_COMPLETED = 'completed';

export const COMPLIANCE_STATUS_COMPLIED = 'complied';
export const COMPLIANCE_STATUS_PENDING = 'pending';
export const COMPLIANCE_STATUS_NEEDS_ASSESSMENT = 'needs_assessment';
export const COMPLIANCE_STATUS_ON_HOLD = 'on_hold';
export const COMPLIANCE_STATUS_NOT_APPLICABLE = 'not_applicable';
export const COMPLIANCE_STATUS_SUPERSEDED = 'superseded';

export const STATUS_OPEN = 'open';
export const STATUS_CLOSED = 'closed';

export const LOGISTIC_STATUSES = {
	[LOGISTIC_STATUS_DRAFT]: {
		title: translate('DRAFT'),
		color: null
	},
	[LOGISTIC_STATUS_PENDING_APPROVAL]: {
		title: translate('PENDING APPROVAL'),
		color: '#fa8c16'
	},
	[LOGISTIC_STATUS_APPROVED]: {
		title: translate('APPROVED'),
		color: '#87d068'
	},
	[LOGISTIC_STATUS_REJECTED]: {
		title: translate('REJECTED'),
		color: '#f50'
	},
	[LOGISTIC_STATUS_IN_PROGRESS]: {
		title: translate('IN PROGRESS'),
		color: '#2db7f5'
	},
	[LOGISTIC_STATUS_ON_HOLD]: {
		title: translate('ON HOLD'),
		color: '#722ed1'
	},
	[LOGISTIC_STATUS_PAYMENT_PENDING]: {
		title: translate('PAYMENT PENDING'),
		color: '#faad14'
	},
	[LOGISTIC_STATUS_ARCHIVED]: {
		title: translate('ARCHIVED'),
		color: null
	},
	[LOGISTIC_STATUS_COMPLETED]: {
		title: translate('COMPLETED'),
		color: '#108ee9'
	}
};

export const COMPLIANCE_STATUSES = {
	[COMPLIANCE_STATUS_COMPLIED]: {
		title: translate('COMPLIED'),
		color: 'green'
	},
	[COMPLIANCE_STATUS_PENDING]: {
		title: translate('PENDING'),
		color: 'orange'
	},
	[COMPLIANCE_STATUS_NEEDS_ASSESSMENT]: {
		title: translate('NEEDS ASSESSMENT'),
		color: 'red'
	},
	[COMPLIANCE_STATUS_ON_HOLD]: {
		title: translate('ON HOLD'),
		color: 'purple'
	},
	[COMPLIANCE_STATUS_NOT_APPLICABLE]: {
		title: translate('NOT APPLICABLE'),
		color: null
	},
	[COMPLIANCE_STATUS_SUPERSEDED]: {
		title: translate('SUPERSEDED'),
		color: 'blue'
	}
};

export const PRICE_COMPARISON_STATUSES = [
	LOGISTIC_STATUS_DRAFT,
	LOGISTIC_STATUS_PENDING_APPROVAL,
	LOGISTIC_STATUS_APPROVED,
	LOGISTIC_STATUS_REJECTED,
	LOGISTIC_STATUS_COMPLETED,
	LOGISTIC_STATUS_ARCHIVED
];

export const PPURCHASE_ORDER_STATUSES = [
	LOGISTIC_STATUS_DRAFT,
	LOGISTIC_STATUS_PENDING_APPROVAL,
	LOGISTIC_STATUS_APPROVED,
	LOGISTIC_STATUS_REJECTED,
	LOGISTIC_STATUS_IN_PROGRESS,
	LOGISTIC_STATUS_ON_HOLD,
	LOGISTIC_STATUS_PAYMENT_PENDING,
	LOGISTIC_STATUS_ARCHIVED
];

export const READ_AND_SIGN_STATUS_PENDING = 'pending';
export const READ_AND_SIGN_STATUS_COMPLETED = 'completed';

export const READ_AND_SIGN_STATUSES = [
	READ_AND_SIGN_STATUS_PENDING,
	READ_AND_SIGN_STATUS_COMPLETED
];


export const SHOPPING_LIST_ITEM_PRIORITY_MAP = {
	4: { text: translate('AOG'), color: 'red' },
	3: { text: translate('HIGH'), color: 'orange' },
	2: { text: translate('MEDIUM'), color: 'blue' },
	1: { text: translate('LOW'), color: 'green' },
	0: { text: translate('NO PRIORITY'), color: null }
};

export const CHANGE_TYPE_MAP = {
	create: translate('New'),
	update: translate('Changed'),
	delete: translate('Removed'),
	none: translate('Unchanged')
};


export const MODS_REPAIRS_TYPE_REPAIR = 'repair';
export const MODS_REPAIRS_TYPE_MODIFICATION = 'modification';

export const MODS_REPAIRS_TYPES = {
	[MODS_REPAIRS_TYPE_REPAIR]: translate('Repair'),
	[MODS_REPAIRS_TYPE_MODIFICATION]: translate('Modification')
};

export const AWR_ROW_TYPE_TITLE = 'title';
export const AWR_ROW_TYPE_CHECK = 'check';

export const AWR_ROW_TYPES = {
	[AWR_ROW_TYPE_TITLE]: translate('Title'),
	[AWR_ROW_TYPE_CHECK]: translate('Check')
};

export const AWR_STATUS_DONE = 'done';
export const AWR_STATUS_OPEN = 'open';
export const AWR_STATUS_CLOSED = 'closed';
export const AWR_STATUS_NEAR_DUE = 'near_due';
export const AWR_STATUS_PAST_DUE = 'past_due';
export const AWR_STATUS_NOT_DUE = 'not_due';


export const AWR_TYPE_INITIAL = 'initial';
export const AWR_TYPE_RECOMMENDATION = 'recommendation';
export const AWR_TYPE_EXTENSION_OF_ARC = 'extension_of_arc';


export const AWR_TYPES = {
	[AWR_TYPE_INITIAL]: translate('Initial'),
	[AWR_TYPE_RECOMMENDATION]: translate('Recommendation'),
	[AWR_TYPE_EXTENSION_OF_ARC]: translate('Extension of ARC'),
};

export const FLIGHTLOGGER_BOOKING_TITLES = {
	SingleStudentBooking: translate('Single student'),
	MultiStudentBooking: translate('Multi student'),
	RentalBooking: translate('Multi student'),
	OperationBooking: translate('Operation')
};

export const OPERATION_SUBTASK_TYPES = {
	task: {
		title: translate('Task'),
		isInfo: false,
		isInput: false,
	},
	input: {
		title: translate('Input'),
		isInfo: false,
		isInput: true,
	},
	chapter: {
		title: translate('Chapter'),
		isInfo: true,
		isInput: false,
	},
};

import 'whatwg-fetch';
import { formatDateForSaving } from '../utils/helpers';
import { LOGBOOK_DATE_FIELDS, STAFF_DATE_FIELDS } from '../utils/constants';

const replacer = (key, value) =>
	(typeof value === 'undefined' ? null : value);

const apiRequest = async (url, reqOpts) => {
	const response = await fetch(url, reqOpts);

	if (response.ok) return response.json();

	throw response;
};

const getDefaultReqOpts = () => ({
	credentials: 'include',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Cache-control': 'no-cache',
		Pragma: 'no-cache'
	}
});

export const apiGet = async (url) => {
	const reqOpts = getDefaultReqOpts();
	const req = await apiRequest(url, reqOpts);
	return req;
};

export const apiPut = async (url, options) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'PUT';
	reqOpts.body = JSON.stringify(options.payload, replacer);

	const req = await apiRequest(url, reqOpts);

	return req;
};

export const apiPost = async (url, options) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'POST';
	reqOpts.body = JSON.stringify(options.payload, replacer);

	const req = await apiRequest(url, reqOpts);

	return req;
};

export const apiPostFile = async (url, options) => {
	const reqOpts = {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Cache-control': 'no-cache',
			Pragma: 'no-cache'
		},
		body: options.file
	};

	const req = await apiRequest(url, reqOpts);

	return req;
};

export const apiGetFile = async (url, options) => new Promise((resolve, reject) => {
	let filename = '';

	fetch(url)
		.then((result) => {
			if (!result.ok) {
				return reject(result.statusText);
			}

			const header = result.headers.get('Content-Disposition');
			const parts = header.split(';');
			filename = parts[1].split('=')[1].replaceAll('"', '');

			return result.blob();
		})
		.then((blob) => {
			if (blob != null) {
				const href = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = href;
				a.download = filename;
				document.body.appendChild(a);
				a.setAttribute('target', '_blank');
				a.target = '_blank';
				a.click();
				a.remove();

				return resolve();
			}
		})
		.catch(err => reject(err));
});

export const apiDelete = async (url) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'DELETE';
	const req = await apiRequest(url, reqOpts);

	return req;
};


// SELF
export const apiGetUserSelf = async (options) => {
	const req = await apiGet('/api/self', options);
	return req;
};

export const apiGetApiToken = async (options) => {
	const req = await apiGet('/api/self/api-token', options);
	return req;
};

export const apiGenerateApiToken = async (options) => {
	const req = await apiPost('/api/self/api-token', options);
	return req;
};

export const apiPutSettings = options => apiPut('/api/self/settings', options);
export const apiPutViews = options => apiPut('/api/self/views', options);

export const apiPutOrganization = options => apiPut('/api/organization', options);

// USERS
export const apiGetUser = async (id, options) => {
	const req = await apiGet(`/api/user/${id}`, options);
	return req;
};

export const apiGetUserPasswordKey = async (id, options) => {
	const req = await apiGet(`/api/user/${id}/password-key`, options);
	return req;
};

export const apiDeleteUserPasswordKey = async (id, options) => {
	const req = await apiDelete(`/api/user/${id}/password-key`, options);
	return req;
};

// Global messages
export const apiGetGlobalMessages = async (options) => {
	const req = await apiGet('/api/global-messages', options);
	return req;
};

export const closeGlobalMessage = async (id, options = { payload: {} }) => {
	const req = await apiPost(`/api/global-message/${id}`, options);
	return req;
};

export const createOrEditUser = async (options) => {
	const existingRecordId = options.payload.id;
	const modifiedOptions = { ...options, payload: formatDateForSaving(options.payload, STAFF_DATE_FIELDS) };

	if (existingRecordId) {
		return apiPut(`/api/user/${existingRecordId}`, modifiedOptions);
	}
	return apiPost('/api/users', modifiedOptions);
};

export const apiPostUser = async (options) => {
	const req = await apiPost('/api/users', options);
	return req;
};

export const apiPutUser = async (id, options) => {
	const req = await apiPut(`/api/user/${id}`, options);
	return req;
};

export const apiDeleteUser = async (id, options) => {
	const req = await apiDelete(`/api/user/${id}`, options);
	return req;
};

export const apiGetUsers = async (query, options) => {
	const req = await apiGet(`/api/users/${query || ''}`, options);
	return req;
};

export const apiGetUsersStats = async (query = '', options) => {
	const req = await apiGet(`/api/users/stats${query}`, options);
	return req;
};

export const apiGetLogbook = async (userId, query, options) => {
	const req = await apiGet(`/api/user/${userId}/logbook-entries${query}`, options);
	return req;
};

export const createOrEdiLogbookEntry = async (userId, options) => {
	const existingRecordId = options.payload.id;
	const modifiedOptions = { ...options, payload: formatDateForSaving(options.payload, LOGBOOK_DATE_FIELDS) };

	if (existingRecordId) {
		return apiPut(`/api/user/${userId}/logbook-entry/${existingRecordId}`, modifiedOptions);
	}
	return apiPost(`/api/user/${userId}/logbook-entries`, modifiedOptions);
};

export const apiDeleteLogbookEntry = async (userId, id, options) => {
	const req = await apiDelete(`/api/user/${userId}/logbook-entry/${id}`, options);
	return req;
};

export const submitImport = async (userId, options) => {
	const req = await apiPost(`/api/user/${userId}/logbook-xlsx`, options);
	return req;
};


export const apiGetUserChangelog = async (id, options) => {
	const req = await apiGet(`/api/user/${id}/changelog${options?.query ? options.query : ''}`, options);
	return req;
};

// OTHER

export const apiLogin = options => apiPost('/auth/login', options);

export const apiRegister = options => apiPost('/auth/register', options);

export const apiSendPasswordLink = options => apiPost('/auth/send-password-link', options);
export const apiResetPassword = options => apiPost('/auth/reset-password', options);

export const apiGetOrganizations = async (id, options) => {
	const req = await apiGet('/api/organizations/', options);
	return req;
};

export const apiGetPasswordKey = async (key, options) => {
	const req = await apiGet(`/auth/password-key/${key}`, options);
	return req;
};

export const apiChangeOrganization = async (options) => {
	const req = await apiPost('/api/organizations/', options);
	return req;
};
